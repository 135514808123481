/*
 * define a few colors that we need to use here
 * since we can't import our JS colors
 */
:root {
  --primary-navy: #242d3b;
  --primary-orange: #e97c26;
}

body {
  min-width: 555px;
}

/* Start of antd dropdown styling overrides */
div.ant-popover-inner-content {
  padding: 0;
  margin-top: 0 !important;
}
div.client-select > div {
  margin-top: 15px !important;
}
li.ant-menu-item {
  padding: 0;
  /* margin: 220 0 0 0 !important; */
  color: var(--primary-navy) !important;
  background-color: white !important;
}
li.ant-menu-item.ant-menu-item-active {
  color: var(--primary-orange) !important;
}
/* End of antd dropdown styling overrides */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* camelCase items are being phased out */
.componentBorder,
.component-border {
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.componentHeader,
.component-header {
  font-weight: bold;
  text-transform: uppercase;
}

/* Typography Definitions from Figma Comps */
.page-title {
  font-size: 44px;
  font-weight: 500;
  font-style: normal;
  line-height: 52px;
  letter-spacing: 0.02em;
  color: #262626; /* neutrals-9 */
}
h1 {
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #262626; /* neutrals-9 */
}
h2 {
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #262626; /* neutrals-9 */
}
h3 {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  height: 30px;
  letter-spacing: 0.02em;
  color: #262626; /* neutrals-9 but on mobile layout: primary-navy */
}
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #262626; /* neutrals-9 */
}
h5 {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #262626; /* neutrals-9 */
}
h6 {
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #262626; /* neutrals-9 */
}
.label-large {
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.label-medium {
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #262626; /* neutrals-9 */
}
.label-small {
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.body-large {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.body-large-bold {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #262626; /* neutrals-9 */
}
.body-medium {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.body-medium-bold {
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  line-height: 24px;
}
.body-small {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  color: #262626; /* neutrals-9 */
}
.body-small-bold {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  line-height: 18px;
}
.component-box-shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
}

.portfolio-box-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.side-padding-50 {
  padding: 0px 50px 30px;
  max-width: 1440px;
  margin: auto;
}

.total-funded-bar {
  border: 1px solid #d9d9d9;
  width: 200px;
  height: 8px;
  border-radius: 16px;
  background: linear-gradient(to right, #226ec8 50%, #f0f0f0 50%);
}

.global-loader-outer-div {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: white;
}

.global-loader-inner-div {
  display: flex;
  justify-content: center;
  padding: 50px;
}
