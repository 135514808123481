/* #1 Regular Weight */
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: normal;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-Regular.otf") format('opentype');
}

/* #2 Italic Style */
@font-face {
	font-family: "Gilroy";
	font-style: italic;
	font-weight: normal;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-RegularItalic.otf") format('opentype');
}

/* #3 Bold Weight */
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: bold;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-Bold.otf") format('opentype');
}

/* #4 Bold Italic Style */
@font-face {
	font-family: "Gilroy";
	font-style: italic;
	font-weight: bold;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-BoldItalic.otf") format('opentype');
}
/* #5 Medium Weight */
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 500;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-Medium.otf") format('opentype');
}

/* #6 Medium Italic Style */
@font-face {
	font-family: "Gilroy";
	font-style: italic;
	font-weight: 500;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-MediumItalic.otf") format('opentype');
}

/* #7 SemiBold Weight */
@font-face {
	font-family: "Gilroy";
	font-style: normal;
	font-weight: 600;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-SemiBold.otf") format('opentype');
}

/* #8 SemiBold Italic Style */
@font-face {
	font-family: "Gilroy";
	font-style: italic;
	font-weight: 600;
	src: url("./Gilroy/Radomir Tinkov - Gilroy-SemiBoldItalic.otf") format('opentype');
}
